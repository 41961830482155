import React from "react";
import bg1 from "./managementassets/Group 48.png";
import bg2 from "./managementassets/Group 49.png";
import bg3 from "./managementassets/Group 50.png";
import bg4 from "./managementassets/Group 52.png";
import bg5 from "./managementassets/Group 53.png";
import { Link } from "react-router-dom";

const ManagementSection = () => {
  const stats = [
    { value: "13k+", label: "Successful Admissions" },
    { value: "99.8%", label: "Students Satisfaction" },
    { value: "15+", label: "Years Experience" },
  ];

  return (
    <div className="max-w-7xl mx-auto p-8 bg-white relative pt-[8rem]">
      <h1 className="absolute top-20 -left-40 w-full text-center text-[8rem] font-semibold text-[#303030] drop-shadow-lg">
        MANAGEMENT
      </h1>

      <div className="grid lg:grid-cols-2 gap-8">
        {/* Left Content */}
        <div className="space-y-6 lg:mt-28">
          <p className="text-gray-700 text-lg leading-relaxed">
            At Aspirants Ally, we support aspiring medical professionals through
            every admissions step. With expert advisors, personalized guidance,
            and comprehensive resources, we simplify the journey from course
            selection to enrollment, empowering future medical leaders with
            confidence and clarity.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed h-[200px]">
            We empower aspiring medical professionals by providing expert
            advisors, personalized guidance, and comprehensive resources
            throughout every stage of the admissions journey. Our dedicated team
            offers tailored support, from course selection to application
            guidance, providing a solid foundation for a successful medical
            career.
          </p>

          {/* <Link to="/career" className="inline-block">
            <button className="px-6 py-3 bg-[#F6BD60] text-white font-semibold rounded-full hover:bg-[#2338718a] transition duration-300">
              Learn More
            </button>
          </Link> */}
          {/* <Link
            to="/career"
            className="text-white group  rounded-full px-10 py-3 text-sm sm:text-base md:text-lg  font-normal bg-[#233871] hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb]  transition-transform duration-800 ease "
          >
            Learn More
            <span class="group-hover:rotate-45 ml-4 ">↗</span>
          </Link> */}

          <Link
            to="/career"
            class="group relative z-0 bg-[#233871] bg rounded-full w-[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-6 py-3 text-white transform-gpu duration-300 ease-in-out active:translate-y-[1px] mt-[1.6rem]"
          >
            Learn More
            <span class="group-hover:rotate-45 ml-4 ">↗</span>
          </Link>

          {/* Stats Section */}
          <div className="grid grid-cols-3 gap-4 pt-8">
            {stats.map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-3xl font-bold text-gray-800 mb-1">
                  {stat.value}
                </div>
                <div className="text-sm text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Image Grid */}
        <div className="grid grid-cols-2 gap-2">
          {/* Left Section - 2x2 Grid */}
          <div>
            <div className="grid  grid-cols-2 gap-2 h-fit">
              <img
                src={bg5}
                alt="Management"
                className="rounded-lg shadow-md w-full h-32 object-cover"
              />
              <img
                src={bg4}
                alt="Management"
                className="rounded-lg shadow-md w-full h-40 object-cover"
              />
              <img
                src={bg4}
                alt="Management"
                className="rounded-lg shadow-md w-full h-32 object-cover"
              />
              <img
                src={bg5}
                alt="Management"
                className="rounded-lg shadow-md w-full h-40 object-cover"
              />
            </div>
            <img
              src={bg3}
              alt="Management"
              className="rounded-lg shadow-md w-full h-56  object-cover mt-4"
            />
          </div>

          {/* Right Section - Single Column */}
          <div className="grid gap-2 h-fit">
            <img
              src={bg1}
              alt="Management"
              className="rounded-lg shadow-md w-full h-100 object-cover"
            />
            <img
              src={bg2}
              alt="Management"
              className="rounded-lg shadow-md w-full h-64 object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementSection;
