import React from "react";
import OurProgress from "./Ourprogress";
import OurOfferings from "./OurOfferings";
import OurInvestors from "./Inverstorscards";
import LogoGrid from "./logo";
import OperatorGrid from "./IndividualOperators";
import AboutOurWork from "./AboutOurwork";
import OurStudents from "./ourStudents";
import { Link } from "react-router-dom";

const OurStory = () => {
  const scrollTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="relative bg-gradient-to-br from-blue-100 to-orange-100 w-full h-full">
      {/* Background image for this section only */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat z-0 w-full h-full"
        style={{
          backgroundImage:
            "url('https://assets.leverageedu.com/about-us/our-team-bg-web.png')",
          opacity: 0.1,
        }}
      ></div>

      {/* Content container */}
      <div className="relative z-10 container mx-auto px-6 md:px-16 py-16">
        <h2 className="text-3xl font-bold text-[#3e5ba9]">
          Our
          <span className="text-[#f6c16f]"> Story</span>
        </h2>

        {/* Split content into two portions */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Text content - Left side */}
          <div className="md:w-1/2 pr-8 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold text-[#3e5ba9]">
              This is
              <span className="text-[#f6c16f]"> us</span>
            </h2>

            <p className="text-gray-700 mb-6">
              Aspirants Ally helps students figure out what they should do and
              where they should be at - wherever that be in the world - to
              realise their full potential. It helps students navigate & assess
              their journey thus far, matches them to personalised mentors,
              counsels them towards their higher education dream with an
              employability lens, and makes sure that everyone punches way above
              their weight to make dreams come alive and truly be able to level
              up.
            </p>
            {/* <Link onClick={scrollTotop} to="/career">
              <button className="bg-[#233871] text-white px-6 py-2 rounded-full hover:bg-[#2338718a] font-poppins transition-colors flex items-center">
                Know More
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </Link> */}
            <Link
              to="/career"
              class="group relative z-0 bg-[#233871] bg rounded-full w-[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-6 py-3 text-white transform-gpu duration-300 ease-in-out active:translate-y-[1px] mt-[1.6rem]"
            >
              Know More
              <span class="group-hover:rotate-45 ml-4 ">↗</span>
            </Link>
          </div>

          {/* Video container - Right side */}
          <div className="md:w-1/2 p-4 md:p-8">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className=" p-4">
                {/* <ImageCloud imageUrls={profileImageUrls} /> */}
                {/* <img src="/asp_png.png" alt="Google Logo" className="w-full h-full" /> */}
                <video
                  autoPlay
                  muted
                  loop
                  src="/edu.mp4"
                  alt="Leverage Edu video thumbnail"
                  className="w-full h-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Components Section */}
      <OurProgress />
      {/* <OurOfferings /> */}
      <OurInvestors />
      <LogoGrid />
      {/* <OperatorGrid /> */}
      <AboutOurWork />
      <OurStudents />
    </div>
  );
};

export default OurStory;
