import React from "react";
import R1 from "../../Images/Destination/R1.png";
import R2 from "../../Images/Destination/R2.png";
import R3 from "../../Images/Destination/R3.png";
import { Award, GraduationCap } from "lucide-react";

const UniversityCard = ({
  name,
  shortName,
  location,
  ielts,
  ranking,
  imageUrl,
  accentColor,
}) => {
  return (
    <div className="relative group rounded-2xl overflow-hidden h-[400px] transition-transform hover:scale-[1.02]">
      {/* Background Image */}
      <img src={imageUrl} alt={name} className="w-full h-full object-cover" />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/80" />

      {/* Content */}
      <div className="absolute inset-0 p-4 md:p-6 flex flex-col">
        {/* Top Section */}
        <div>
          <span className="inline-block px-3 py-1 bg-emerald-400 text-white text-xs md:text-sm rounded-full">
            University
          </span>
          <p className="text-white mt-1 text-xs md:text-sm">{location}</p>
        </div>

        {/* University Name */}
        <div className="mt-2 md:mt-4">
          <h2 className="text-white text-xl md:text-2xl font-bold">
            The University of
          </h2>
          <h3 className={`text-2xl md:text-3xl font-bold ${accentColor}`}>
            {shortName}
          </h3>
        </div>

        {/* Stats */}
        <div className="">
          <div className="flex items-center text-white gap-2 my-4">
            <GraduationCap size={20} />
            <span className="text-xs md:text-sm">
              {ielts} Minimum IELTS Required
            </span>
          </div>

          <div className="flex items-center text-white gap-2 my-4">
            <Award size={20} />
            <span className="text-xs md:text-sm">{ranking} Ranking</span>
          </div>

          {/* Apply Button */}
          <button className="w-1/2 bg-white text-blue-900 py-1 rounded-lg mt-4 md:mt-6 hover:bg-gray-100 transition-colors">
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

const UniversityShowcase = () => {
  const universities = [
    {
      name: "The University of Banglore",
      shortName: "Banglore",
      location: "Bangalore, INDIA",
      ielts: 6,
      ranking: 32,
      imageUrl: R1,
      accentColor: "text-pink-500",
    },
    {
      name: "University of Barkatullah",
      shortName: "Barkatullah",
      location: "Bhopal, India",
      ielts: 6,
      ranking: 32,
      imageUrl: R2,
      accentColor: "text-emerald-400",
    },
    {
      name: "University of Management",
      shortName: "Management",
      location: "Mumbai, India",
      ielts: 6,
      ranking: 32,
      imageUrl: R3,
      accentColor: "text-red-400",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {universities.map((uni, index) => (
          <UniversityCard key={index} {...uni} />
        ))}
      </div>

      {/* Find Your Dream University Button */}
      <div className="text-center mt-8 md:mt-12">
        <button className="bg-blue-900 text-white px-6 py-2 rounded-lg hover:bg-blue-800 transition-colors">
          Find Your Dream University
        </button>
      </div>
    </div>
  );
};

export default UniversityShowcase;
