import React from "react";
import ManagementSection from "../components/managementComponent/hero.jsx";
import ManagementProgram from "../components/managementComponent/managementProgram";
import ProfileStoriesUI from "../components/managementComponent/ProfileStoriesUi.jsx";
import ManagementImage from "../components/managementComponent/ManagementImage.jsx";
import ManagementCourse from "../components/managementComponent/ManagementCourse.jsx";
import Pg from "../components/EngineeringComponenet.jsx/Pg.jsx";

const Management = () => {
  return (
    <>
      <ManagementSection />
      <ManagementProgram />
      <div className="pb-10">
        <div className="space-y-4 mb-4 text-center">
          <h1 className="text-4xl font-bold text-navy-900">
            What we offer
            {/* <span className="text-orange-400 block">
              Engineering Education?
            </span> */}
          </h1>
          <p className="text-gray-600 text-lg">
            Unleash Your Potential with a Future-Ready Engineering Program
            Designed for Success.
          </p>
        </div>
        <Pg />
      </div>
      <ProfileStoriesUI />
      <ManagementImage />
      <ManagementCourse />
    </>
  );
};

export default Management;
