const TrendingBanner = () => {
  return (
    <div className="w-full bg-blue-900 flex items-end justify-center min-h-[5rem]">
      <h1 className="text-white text-center text-2xl -mb-[8px] md:text-3xl lg:text-5xl xl:text-5xl font-bold tracking-wider">
        TOP UNIVERSITIES TO STUDY IN NEW ZEALAND
      </h1>
    </div>
  );
};

export default TrendingBanner;
