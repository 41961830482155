

import React from "react";

const courses = [
  {
    title: "MBBS Abroad",
    color: "bg-orange-200",
    description:
      "MBBS Abroad signifies the pursuit of a medical education by enrolling in a medical school located in a foreign country, distinct from the medical institutions within one's own nation.",
  },
  {
    title: "USMLE",
    color: "bg-teal-200",
    fullForm:"(United States Medical Licensing Examination)",
    description:
      "A three-step examination for medical licensure in the United States.",
  },
  {
    title: "PLAB",
    color: "bg-purple-200",
    fullForm:"(Professional and Linguistic Assessments Board)",
    description:
      "A two-part examination for international medical graduates seeking to practice medicine in the United Kingdom.",
  },
  {
    title: "MCCQE",
    color: "bg-teal-300",
    fullForm:"(Medical Council of Canada Qualifying Examination)",
    description:
      "A three-step examination for medical licensure in Canada.",
  },
  {
    title: "Physician Associate",
    color: "bg-red-300",
    description:
      "A healthcare professional who works collaboratively with physicians to provide patient care.",
  },
  {
    title: "Paramedical Courses",
    color: "bg-teal-400",
    description:
      "A diverse range of healthcare courses that focus on allied health professions, such as radiology, physiotherapy, and pharmacy.",
  },
];

function CourseCard({ title,color,description,fullForm }) {
  return (
    <div
      className={`relative rounded-lg p-4 ${color} shadow-lg w-68 h-52 flex flex-col justify-between overflow-hidden`}
    >
      <div>
        <div className="flex">
        <h3 className="text-2xl text-white font-bold mb-2">{title}</h3>
        <span className="text-[12px] flex justify-center items-center ml-2 text-gray-500">{fullForm}</span>
        </div>
       
        <p className="text-sm text-gray-600">
          {description}
        </p>
      </div>
      <div className="flex items-center justify-between text-sm text-gray-600">
        <div className="flex items-center space-x-2">
          <span>❤️ 20k</span>
          <span>🔄</span>
        </div>
      </div>

       <div
        style={{
          clipPath: "circle(50.4% at 100% 100%)",
        }}
        className="absolute bottom-0 right-0 h-40 w-40 bg-white"
      >
      <p className={`z-10 relative text-[${color}] mt-[112px] ml-[102px]`}>View➔</p>
      </div>
    </div> 
   

     
  );
}

const MedicalCourse = () => {
  return (
    <div className="pt-28 flex flex-col items-center max-w-7xl mx-auto" >
      <h2 className="text-4xl font-semibold mb-16 mt-8">Our Courses</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 py-4">
        {courses.map((course, index) => (
          <>
           <CourseCard key={index} title={course.title} color={course.color} description={course.description} fullForm={course.fullForm} />
          </>
         
        ))}
      </div>
    </div>
  );
};

export default MedicalCourse;
