import React from "react";
import ShimmerButton from "../magicui/shimmer-button";
import ShimmerButtonTwo from "../magicui/shimmer-button-Two";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className="relative h-[900px] w-full flex items-center justify-center bg-slate-50 overflow-hidden pt-[14rem]">
        <div
          className="absolute w-80 h-40 top-10 -left-4 z-10 md:bg-white sm:bg-none"
          style={{ clipPath: "ellipse(65% 80% at 28% 0)" }}
        ></div>
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
        >
          <source src="/edu2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute top-0 left-0 w-full h-[900px] bg-gradient-to-b from-black/50 to-black/30 z-5"></div>

        <div className="relative z-10 flex flex-col md:flex-row items-center justify-between w-full px-6 md:px-12 lg:px-10 py-10 md:py-24 gap-[20rem]">
          {/* Left Section */}
          <div className="flex flex-col items-center md:items-start text-left w-full md:w-1/2 md:gap36 gap-12">
            <p className="text-white text-center text-sm sm:text-base md:text-lg md:text-left lg:text-xl font-light leading-relaxed">
              Comprehensive Guidance for Aspiring Medical Professionals From{" "}
              <span className="font-medium">Entrance Exams</span> to{" "}
              <span className="font-medium">Admissions</span>.
            </p>
            <h1 className="text-white  text-center md:text-left text-3xl sm:text-3xl md:text-7xl lg:text-6xl font-semibold tracking-wider leading-snug">
              Your Trusted Ally in Overseas Education.
            </h1>
            <div className="flex  flex-row items-center justify-start gap-6 mt-20">
              <Link
                to="/about"
                className="text-white text-sm sm:text-base md:text-lg hover:text-gray-300 border-b font-normal"
              >
                Learn More →
              </Link>
              <Link
                to="/about"
                className="text-white text-sm sm:text-base md:text-lg hover:text-gray-300 border-b font-normal"
              >
                Enquire Now →
              </Link>
            </div>
          </div>

          {/* Right Section */}
          <div className="flex flex-col md:items-end text-right w-full md:w-1/2 md:gap-y-[12rem]">
            <p className="text-white  text-center md:text-left text-sm sm:text-base md:text-lg lg:text-xl font-light border-b leading-relaxed">
            At Aspirants ally, we provide professional counseling and a proven track record. Aspirant’s Ally offers expert guidance for both UG and PG in Engineering, Management, IELTS, TOEFLE, GRE, GMAT, SAT, MBBS Abroad, USMLE, Residency program, Physician associate, MCCQE, PLAB, and any other medical & paramedical courses.
            </p>
            <div className="flex flex-col items-start mdend gap-4 mt-6">
              {/* <Link
                to="/otp"
                className="text-white group  rounded-full px-10 py-3 text-sm sm:text-base md:text-lg  font-normal bg-[#233871] hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb]  transition-transform duration-800 ease "
              >
                Start Your Journey Today{" "}
                <span class="group-hover:rotate-45 ml-4 ">↗</span>
              </Link> */}
              <Link
                to="/contact"
                class="group relative z-0 bg-[#233871] bg rounded-full w[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-[#2338718a] px-6 py-4 text-white transform-gpu duration-300 ease-in-out mt-[1.6rem]"
              >
                Start Your Journey Today{" "}
                <span class="group-hover:rotate-45 ml-4 ">↗</span>
              </Link>
              <Link
                to="/psychometric-test"
                className=" group relative text-[#233871] group text-sm sm:text-base md:text-lg bg-[#fff] rounded-full px-5 py-3 border-b font-normal"
              >
                Take Psychometric Assessment
                <span class="group-hover:rotate-45 ml-4 ">↗</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
