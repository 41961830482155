import React from "react";
import MedicalHero from "../components/medicalComp/MedicalHero";
import MedicalSection from "../components/medicalComp/MedicalHero";
import BenefitsSection from "../components/medicalComp/BenefitsSection";
import MedicalStories from "../components/medicalComp/MedicalStories";
import MedicalImage from "../components/medicalComp/MedicalImage";
import MedicalCourse from "../components/medicalComp/MedicalCourse";
import ServiceHero from "../components/ServiceComp/ServiceHero";

const Medical = () => {
  return (
    <>
      {/* <MedicalHero /> */}
      <MedicalCourse />
      <MedicalSection />
      <div className="h-[900px]">
        <BenefitsSection />
      </div>
      <ServiceHero />
      <MedicalStories />
      <MedicalImage />
    </>
  );
};

export default Medical;
