
import { Link as ScrollLink } from "react-scroll";
import React, { useState, useEffect } from "react";

import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navHeight = 600;
      setIsFixed(window.scrollY >= navHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    { name: "FAST FACTS", to: "studyHero" },
    { name: "TOP UNIVERSITIES", to: "topUniversities" },
    { name: "ADMISSIONS", to: "admissions" },
    { name: "SCHOLARSHIPS", to: "scholarships" },
    { name: "VISA", to: "visa" },
    { name: "COST OF LIVING", to: "costOfLiving" },
    { name: "WORK OPPORTUNITIES", to: "opportunities" },
    { name: "FAQS", to: "faqs" },
  ];

  return (
    <nav
        className={`${
          isFixed ? "w-full fixed top-[120px] rounded" : "relative md:w-[90%] mx-auto"
        } z-50 bg-white shadow-md rounded`}
    >
      <div
          className={`container mx-auto flex justify-between items-center px-4 py-4 ${
            isFixed ? "mt-0" : "-mt-[2rem]"
          }`}
        >
        <button
          className="md:hidden absolute left-4 z-50" 
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {isOpen ? (
            <X className="w-6 h-6 text-[#233871]" />
          ) : (
            <Menu className="w-6 h-6 text-[#233871]" />
          )}
        </button>

        {/* Desktop Menu */}
        <ul className="hidden md:flex justify-center space-x-8">
          {menuItems.map((item, index) => (
            <li key={index} className="cursor-pointer px-4 font-poppins">
              <ScrollLink
                to={item.to}
                smooth={true}
                duration={500}
                spy={true}
                activeClass="text-[#233871] border-b-2 border-[#233871]"
                className="text-[#233871] hover:text-[#f6c16f] font-poppins"
              >
                {item.name}
              </ScrollLink>
            </li>
          ))}
        </ul>

        {/* Mobile Menu (dropdown when hamburger is clicked) */}
        {isOpen && (
          <ul className="md:hidden fixed top-0 left-0 w-full h-screen bg-white flex flex-col items-center justify-center space-y-6 z-40">
            {" "}
            {/* Fullscreen Menu */}
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="text-[#233871] hover:text-[#f6c16f] cursor-pointer px-4 text-xl"
              >
                <ScrollLink
                  to={item.to}
                  smooth={true}
                  duration={500}
                  spy={true}
                  activeClass="text-[#233871] border-b-3 border-[#233871]"
                  onClick={toggleMenu} // Close menu on click
                  className="cursor-pointer"
                >
                  {item.name}
                </ScrollLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
