import React from "react";
import Hero from "../components/homeComponent/Hero";
import { CollegeMarquee } from "../components/homeComponent/CollegeMarquee";
import TextRevealByWord from "../components/magicui/text-reveal";
// import CoursesSection from "../components/homeComponent/Courses";
import CoursesSection2 from "../components/homeComponent/CourseSection";
import Offerings from "../components/homeComponent/OfferSection";
import { OrbitingCirclesDemo } from "../components/homeComponent/sub/OrbitingCirclesDemo";
import { ReviewMarquee } from "../components/homeComponent/sub/ReviewMarquee";
import Accordion from "../components/homeComponent/FAQ";
import GetStarted from "../components/homeComponent/GetStarted";
import InfoSectionWithSwiper from "../components/homeComponent/InfoSectionWithSwiper";
import Specialised from "../components/homeComponent/Specialised";
import BlogSection from "../components/homeComponent/BlogSection";


const Home = ({ OpenForm }) => {
  return (
    <>
      {/* <div className="pt-10"> */}
      <Hero />
      {/* </div> */}
      <div className="flex h-auto w-full items-center justify-around overflow-hidden bg-slate-50  mt">
        <CollegeMarquee />
      </div>
      <TextRevealByWord text="We are a dedicated team of experts guiding Aspiring  Professionals on their journey 
towards academic success. From Undergraduate to Postgraduate programs, we offer 
Personalized consultancy services Tailored to individual needs. Our commitment extends 
beyond mere guidance; we provide unwavering support throughout the entire Admission 
Process, assisting students in selecting the most suitable courses, Mastering challenging 
entrance exams, and navigating the complexities of university applications. With a client
centric approach and a proven track record of success, Aspirant's Ally empowers medical 
aspirants to confidently pursue their dreams and embark on a rewarding career in 
healthcare." />
      <Offerings OpenForm={OpenForm} />
      {/* <CoursesSection /> */}
      {/* <Specialised /> */}
      <CoursesSection2 />
      <InfoSectionWithSwiper />
      <BlogSection />
      <div className="relative flex h-[461px] w-full items-end justify-normal overflow-hidden bg-slate-50 pt-12">
        {/* <div className="pointer-events-none absolute inset-y-56 bottom-0 w-full bg-gradient-to-b  from-transparent to-white dark:from-background z-30"></div> */}
        <div className="text-center px-4 -left-20  absolute top-16 md:translate-y-1 md:translate-x-1/2">
          <h2 className="text-3xl font-bold text-[#3e5ba9]">
            Hear From Our Successful{" "}
            <span className="text-[#f6c16f]">Aspirants</span>
          </h2>
          <p className="text-sm text-gray-500">
            Discover how Aspirant’s Ally has helped students achieve their
            medical dreams with our professional counseling services.
          </p>
          <div className="mt-2 w-14 h-1 bg-[#233871] mx-auto"></div>
        </div>
        {/* <OrbitingCirclesDemo /> */}

        <ReviewMarquee />
      </div>

      <div className="flex flex-col h-auto w-full items-center gap-y-8 md:gap-y-12 lg:gap-y-16 justify-self-start overflow-hidden bg-slate-50 p-4 md:p-8 lg:p-12">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-[#3e5ba9]">
            Frequently Asked
            <span className="text-[#f6c16f]">Questions</span>
          </h2>
          <div className="mt-2 w-10 md:w-12 lg:w-14 h-1 bg-[#233871] mx-auto"></div>
        </div>
        <Accordion />
      </div>

      <GetStarted />
      {/* <FooterNew /> */}

    </>
  );
};

export default Home;
