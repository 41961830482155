import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import MainLayout from "./pages/mainLayout";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Destination from "./pages/Destination";
import AboutUs from "./pages/AboutUs";
import HelloMentor from "./pages/helloMentor";
import TestGmat from "./pages/TestGmat";
import TestPte from "./pages/TestPte";
import Neet from "./pages/Neet";
import Collaboration from "./pages/Collaboration";
import TestTOEFL from "./pages/TestTOEFL";
import TestGRE from "./pages/TestGRE";
import TestIELTS from "./pages/TestIELTS";
import PsychometricTestPage from "./pages/PsychometricTest";
import India from "./pages/India";
import UnitedState from "./pages/UnitedState";
import France from "./pages/France";
import Japan from "./pages/Japan";
import Canada from "./pages/Canada";
import SriLanka from "./pages/SriLanka";
import Israel from "./pages/Israel";
import Germany from "./pages/Germany";
import Dubai from "./pages/Dubai";
import NewZealand from "./pages/NewZealand";
import Australia from "./pages/Australia";
import Spain from "./pages/Spain";
import Poland from "./pages/Poland";
import Netherland from "./pages/Netherland";
import Italy from "./pages/Italy";
import Europe from "./pages/Europe";
import Ireland from "./pages/IrelandPage";
import Career from "./pages/Career";
import Medical from "./pages/Medical";
import Management from "./pages/management";
import Engineering from "./pages/Engineering";
import Login from "./pages/Login";
import BlogPage from "./pages/BlogPage";
import Contact from "./pages/Contact";
import { IoLogoWhatsapp } from "react-icons/io";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="*"
            element={
              <MainLayout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/career" element={<Career />} />
                  <Route path="/medical" element={<Medical />} />
                  <Route path="/management" element={<Management />} />
                  <Route path="/service" element={<Services />} />
                  <Route path="/uk" element={<Destination />} />
                  <Route path="/india" element={<India />} />
                  <Route path="/us" element={<UnitedState />} />
                  <Route path="/france" element={<France />} />
                  <Route path="/japan" element={<Japan />} />
                  <Route path="/canada" element={<Canada />} />
                  <Route path="/srilanka" element={<SriLanka />} />
                  <Route path="/is" element={<Israel />} />
                  <Route path="/ireland" element={<Ireland />} />
                  <Route path="/germany" element={<Germany />} />
                  <Route path="/dubai" element={<Dubai />} />
                  <Route path="/newzealand" element={<NewZealand />} />
                  <Route path="/australia" element={<Australia />} />
                  <Route path="/spain" element={<Spain />} />
                  <Route path="/poland" element={<Poland />} />
                  <Route path="/netherland" element={<Netherland />} />
                  <Route path="/italy" element={<Italy />} />
                  <Route path="/europe" element={<Europe />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/helloMentor" element={<HelloMentor />} />
                  <Route
                    path="/psychometric-test"
                    element={<PsychometricTestPage />}
                  />
                  <Route path="/test/gmat" element={<TestGmat />} />
                  <Route path="/test/ielts" element={<TestIELTS />} />
                  <Route path="/test/pte" element={<TestPte />} />
                  <Route path="/test/gre" element={<TestGRE />} />
                  <Route path="/test/toefl" element={<TestTOEFL />} />
                  <Route path="/neet" element={<Neet />} />
                  <Route path="/blogs" element={<BlogPage />} />
                  <Route path="/collaboration" element={<Collaboration />} />
                  <Route path="/engineering" element={<Engineering />} />
                </Routes>
              </MainLayout>
            }
          />
        </Routes>
      </div>

      <div>
        <Link
          to="https://wa.link/72wmgj"
          className="px-5 py-2 bg-green-400 items-center fixed bottom-10 rounded-l-full right-0 flex font-semibold z-[99999999] gap-2 text-white text-lg"
        >
          <p className="text-2xl">
            <IoLogoWhatsapp />
          </p>
          WhatsApp
        </Link>
      </div>
    </Router>
  );
}

export default App;
