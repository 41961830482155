import React from 'react';
import Landing from '../components/testComponentGmat/Landing';
import SecondSection from '../components/testComponentGmat/SecondSection';
import ThirdSection from '../components/testComponentGmat/ThirdSection';
import FourthSection from '../components/testComponentGmat/FourthSection';
import FIfthSection from '../components/testComponentGmat/FIfthSection';
import SixthSection from '../components/testComponentGmat/SixthSection';
import SeventhSection from '../components/testComponentGmat/SeventhSection';

const TestGmat = () => {
    return (
    <>
   <Landing />
 <SecondSection />
 <ThirdSection />
 <FourthSection />
 <FIfthSection />
 <SixthSection />
 <SeventhSection />
    </>
 );
};

export default TestGmat;
