import React from "react";
import Hero from "../components/blogNewComponent/Hero";
import BlogSection from "../components/blogNewComponent/BlogSection";
import FeaturedSection from "../components/blogNewComponent/FeaturedSection";
import TopDestinations from "../components/blogNewComponent/TopDestinations";
import LatestSection from "../components/blogNewComponent/LatestSection";
import CategoryList from "../components/blogNewComponent/CategoryList";
import MainPost from "../components/blogNewComponent/MainPost";
import SidebarPosts from "../components/blogNewComponent/SidebarPosts";

const BlogPage = () => {
  return (
    <div>
      <Hero />
      <BlogSection />
      <FeaturedSection />
      <TopDestinations />
      <LatestSection />
    </div>

    // fkjhsdlfhasdlkfhlfjds
  );
};

export default BlogPage;
