import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Images/asp_png.png";
import Image1 from "./Exlorer_Illustration 1.png";
import Home from "./home.png";
import axios from "axios";

const LoginPage = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "", // Updated to example email
    mobileNumber: "",
    otp: "",
  });
  const [inputError, setInputError] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // Handle input change for all fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Simulate OTP sending
  // const sendOTP = async () => {
  //   if (!formData.email) {
  //     setInputError("Please enter an email address");
  //     return;
  //   }

  //   try {
  //     await axios.post(
  //       "https://mbbs-backend.onrender.com/api/web/send",

  //       {
  //         email: formData.email,
  //       }
  //     );
  //     // fjkasdlfja;d
  //     setOtpSent(true);
  //     alert("OTP sent to your email");
  //   } catch (error) {
  //     console.error(error);
  //     alert("Failed to send OTP");
  //   }
  //   // flkjdshfkasdhflksadhflasjkh
  //   setInputError("");
  //   setOtpSent(true);
  //   setTimer(60); // Reset timer when OTP is sent
  //   console.log("OTP sent to:", formData.email);
  // };

  const sendOTP = async () => {
    if (!formData.email) {
      setInputError("Please enter an email address");
      return;
    }

    setIsLoading(true); // Start loading
    try {
      await axios.post("https://mbbs-backend.onrender.com/api/web/send", {
        email: formData.email,
      });
      setOtpSent(true);
      alert("OTP sent to your email");
      setInputError("");
      setTimer(60); // Reset timer when OTP is sent
      console.log("OTP sent to:", formData.email);
    } catch (error) {
      console.error(error);
      alert("Failed to send OTP");
    }
    setIsLoading(false); // End loading
  };

  // Handle OTP input
  const handleOtpInput = (index, value) => {
    if (value.length > 1) return; // Prevents entering more than one digit per box
    const newOtpInput = [...otpInput];
    newOtpInput[index] = value;
    setOtpInput(newOtpInput);

    // Automatically focus next input
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  // Verify OTP by combining OTP input array
  // const verifyOTP = async () => {
  //   try {
  //     const res = await axios.post(
  //       "http://localhost:8000/api/web/verify",
  //       formData
  //     );
  //     console.log(res);
  //     setOtpVerified(true);
  //     alert("OTP verified and data saved");
  //     navigate("/");
  //   } catch (error) {
  //     console.error(error);
  //     alert("Invalid OTP");
  //   }
  // };

  // const verifyOTP = async () => {
  //   try {
  //     // Combine OTP digits from otpInput array
  //     const otpCode = otpInput.join("");
  //     console.log(otpCode);
  //     // Include OTP in formData for the request
  //     const updatedFormData = { ...formData, otp: otpCode };

  //     const res = await axios.post(
  //       "https://mbbs-backend.onrender.com/api/web/verify",
  //       updatedFormData
  //     );

  //     console.log(res);
  //     setOtpVerified(true);
  //     alert("OTP verified and data saved");
  //     navigate("/");
  //   } catch (error) {
  //     console.error(error);
  //     alert("Invalid OTP");
  //   }
  // };

  const verifyOTP = async () => {
    setIsLoading(true); // Start loading
    try {
      const otpCode = otpInput.join(""); // Combine OTP digits from otpInput array
      const updatedFormData = { ...formData, otp: otpCode };

      const res = await axios.post(
        "https://mbbs-backend.onrender.com/api/web/verify",
        updatedFormData
      );

      console.log(res);
      setOtpVerified(true);
      alert("OTP verified and data saved");
      navigate("/");
    } catch (error) {
      console.error(error);
      alert("Invalid OTP");
    }
    setIsLoading(false); // End loading
  };

  // Countdown timer effect
  useEffect(() => {
    if (otpSent && timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [otpSent, timer]);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Navigation */}
      <nav className="flex justify-between items-center">
        <div className="flex items-center">
          <img
            src={Logo}
            alt="Aspirant's Ally Logo"
            className="w-[12rem] object-contain"
          />
        </div>

        <div className="flex gap-4 items-center">
          <a href="#" className="text-gray-600 hover:text-gray-800">
            Help
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-800">
            Contact us
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-800">
            Learning
          </a>
          <div className="relative top-0 right-0 w-28 h-28 bg-amber-400 rounded-bl-full flex items-center justify-center">
            <button
              className="text-white mr-4 ml-8"
              onClick={() => navigate("/")}
            >
              <img src={Home} alt="Home Icon" className="w-12 h-12" />
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex min-h-screen items-center justify-center relative overflow-hidden">
        <h1 className="absolute bottom-20 left-44 text-9xl font-semibold text-gray-200 opacity-30 pointer-events-none">
          Aspirant’s Ally
        </h1>

        <div className="flex w-full h-full relative z-10">
          <div className="flex-1 flex items-center justify-center p-8">
            <div className="max-w-md w-full mb-[12rem]">
              <h2 className="text-3xl font-semibold text-gray-700 font-poppins">
                {otpSent ? "Verify Your OTP" : "Welcome Back! Login"}
              </h2>
              Your Data is Safe With Us,{" "}
              <span className="text-[#F6BD60]">Sign Up</span>
              <form className="mt-6" onSubmit={(e) => e.preventDefault()}>
                {!otpSent ? (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="firstName"
                          className="text-sm text-gray-600"
                        >
                          First Name
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="lastName"
                          className="text-sm text-gray-600"
                        >
                          Last Name
                        </label>
                        <input
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="mobileNumber"
                        className="text-sm text-gray-600"
                      >
                        Mobile Number
                      </label>
                      <input
                        id="mobileNumber"
                        type="tel"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="email" className="text-sm text-gray-600">
                        Email Address
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="example@example.com"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                      />
                    </div>
                    <button
                      onClick={sendOTP}
                      disabled={isLoading}
                      className="py-3 px-8 mt-6 bg-yellow-500 hover:bg-yellow-600 text-white font-bold rounded-full"
                    >
                      {isLoading ? "Sending..." : "Send OTP"}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
                      <div className="bg-white py-4 px-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                        <h2 className="text-2xl font-semibold mb-2 text-left">
                          OTP Verification
                        </h2>
                        <p className="text-gray-500 mb-2 text-left">
                          Please enter the code sent to {formData.email}.
                          <span
                            className="text-[#233871] cursor-pointer ml-1"
                            onClick={() => setOtpSent(false)}
                          >
                            Change
                          </span>
                        </p>
                        <div className="flex justify-center space-x-2 mb-2 text-left">
                          {otpInput.map((value, index) => (
                            <input
                              key={index}
                              id={`otp-input-${index}`}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleOtpInput(index, e.target.value)
                              }
                              maxLength="1"
                              className={`w-12 h-12 text-center border ${
                                otpError
                                  ? "border-red-500 bg-red-100"
                                  : "border-gray-300"
                              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                            />
                          ))}
                        </div>
                        {otpError && (
                          <p className="text-red-500 mb-2">
                            Please enter valid code!
                          </p>
                        )}
                        <div className="text-gray-600 mb-4">
                          {timer > 0 ? (
                            `Not received your code? 00:${timer
                              .toString()
                              .padStart(2, "0")}`
                          ) : (
                            <button onClick={sendOTP}>
                              Please enter valid code!{" "}
                              <span className="text-[#233871]">
                                Resend Code
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="flex  space-x-4 justify-end">
                          <button
                            onClick={() => setOtpSent(false)}
                            className="px-4 py-2 border border-[#233871] text-[#233871] rounded-md hover:bg-gray-100"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={verifyOTP}
                            disabled={isLoading}
                            className="bg-[#233871] text-white px-4 py-2 rounded-md"
                          >
                            {isLoading ? "Verifying..." : "Verify OTP"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>

          {/* Right Side: Image */}
          <div className="flex-1 hidden lg:flex items-center justify-center">
            <img src={Image1} alt="Poster Banner" className="max-w-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
