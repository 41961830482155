const TrendingBanner = () => {
    return (
      <div className="w-full bg-blue-900 flex items-end justify-center min-h-[5rem]">
        <h1 className="text-white text-center text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold tracking-wider">
            TOP UNIVERSITIES TO STUDY IN IRELAND
        </h1>
      </div>
      
    );
  };
  
  export default TrendingBanner;