// import React, { useRef } from "react";
// import Navbar from "../components/indianewcomp/navbar";
// import UniversityIndia from "../components/indiaComponent/UniversityIndia";
// import PostAdmissionDashboard from "../components/indiaComponent/PostAdmissionExperienceInd";
// import ExpenseEstimateBanner from "../components/indianewcomp/ExpenseEstimateBanner";
// import FAQSection from "../components/indiaComponent/Faqs";
// import BottomBanner from "../components/indiaComponent/BottomBannerInd";
// import India from "../components/indiaComponent/India";
// import IndAdmissionTimeline from "../components/indiaComponent/AdmisionIndia";
// import IndScholarships from "../components/indiaComponent/SchoarshipsInd";
// import IndCareersOpportunities from "../components/indiaComponent/CareersOpportunities";

// const IndiaCom = () => {
//   return (
//     <div className="w-screen max-w-full overflow-x-hidden">
//       <div id="studyHero">
//         <India />
//       </div>
//       <Navbar />

//       <div id="topUniversities" className="py-10">
//         <UniversityIndia />
//       </div>

//       <div id="admissions" className="py-10">
//         <IndAdmissionTimeline />
//       </div>

//       <div id="scholarships" className="py-10">
//         <IndScholarships />
//       </div>

//       <div id="visa" className="py-10">
//         <PostAdmissionDashboard />
//       </div>
//       <div id="costOfLiving" className="py-10">
//         <ExpenseEstimateBanner />
//       </div>

//       <div id="opportunities" className="py-10">
//         <IndCareersOpportunities />
//       </div>

//       <div id="faqs" className="py-10">
//         <FAQSection />
//       </div>

//       <BottomBanner />
//     </div>
//   );
// };

// export default IndiaCom;

import React, { useRef } from "react";
import Navbar from "../components/indianewcomp/navbar";
import StudyHero from "../components/indianewcomp/StudyHero";
import University from "../components/indianewcomp/TopUniversities";
import UKAdmissionTimeline from "../components/indianewcomp/Admission";
import UKScholarships from "../components/indianewcomp/Scholarships";
import PostAdmissionDashboard from "../components/indianewcomp/PostAdmissionExperience";
import ExpenseEstimateBanner from "../components/indianewcomp/ExpenseEstimateBanner";
import UKCareersOpportunities from "../components/indianewcomp/CareerOpportunities";
import FAQSection from "../components/indianewcomp/Faqs";
import BottomBanner from "../components/indianewcomp/BottomBanner";
import AdmissionRequirements from "../components/indianewcomp/AdmissionRequirements";
import LearnBanner from "../components/indianewcomp/LearnerBanner";
import ScholarshipsSection from "../components/indianewcomp/ScholarshipSection";

const IndiaCom = () => {
  return (
    <div className="w-screen max-w-full overflow-x-hidden">
      <div id="studyHero">
        <StudyHero />
      </div>
      <Navbar />

      <div id="topUniversities" className="py-10">
        <University />
      </div>

      <div id="admissions" className="py-10">
        <UKAdmissionTimeline />
      </div>

      <div id="scholarships" className="py-10">
        <UKScholarships />
      </div>

      <div id="visa" className="py-10">
        <PostAdmissionDashboard />
      </div>
      <div id="costOfLiving" className="py-10">
        <ExpenseEstimateBanner />
      </div>

      <div id="opportunities" className="py-10">
        <UKCareersOpportunities />
      </div>

      <AdmissionRequirements />

      <LearnBanner />

      <ScholarshipsSection />

      <div id="faqs" className="py-10">
        <FAQSection />
      </div>
    </div>
  );
};

export default IndiaCom;
