import React from "react";
import MainPost from "./MainPost";
import SidebarPosts from "./SidebarPosts";
import CategoryList from "./CategoryList";

const LatestSection = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="space-y-6">
          <CategoryList />
          <div className="flex flex-col lg:flex-row gap-4">
            <MainPost />
            <SidebarPosts />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestSection;
