import React from "react";
import LoginPage from "../components/Login/LoginPage";
import ContactUs from "../components/Login/ContactUs";

const Contact = () => {
  return (
    <>
      <ContactUs/>
    </>
  );
};

export default Contact;
