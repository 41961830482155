import React from "react";
import BgImage from "../../Images/Destination/Uk Image 1.png"; // Background image of your choice

const HeroSection = () => {
  const scrollingTextStyles = {
    position: "absolute",
    width: "100%",
    whiteSpace: "nowrap",
    animation: "scroll-left-right 20s linear infinite",
  };

  return (
    <div className="relative h-[400px] md:h-[750px] w-full overflow-hidden">
      {/* Inline keyframes definition */}
      <style>
        {`
          @keyframes scroll-left-right {
            0% {
              transform: translateX(50%);
            }
            50% {
              transform: translateX(-50%);
            }
            100% {
              transform: translateX(50%);
            }
          }
        `}
      </style>

      {/* Background Image */}
      <img
        src={BgImage}
        alt="New Zealand Skyline"
        className="absolute inset-0 h-full w-full object-cover"
        aria-hidden="true"
      />

      {/* Content Container */}
      <div className="relative h-full flex flex-col items-center justify-center text-center space-y-4 px-4 pt-10 md:pt-20">
        {/* Main Title and Overlay */}
        <div className="relative z-10 w-full flex flex-col items-center mt-[3rem]">
          {/* Main Title */}
          <h1
            style={scrollingTextStyles}
            className="text-[44px] text-[#0AC4FF] md:text-[200px] -top-40 font-extralight uppercase tracking-wider z-10 relative"
          >
            STUDY IN IRELAND
          </h1>

          {/* Subtitle with Blurred Background */}
          <div className="relative z-20 backdrop-blur-sm py-16 max-w-[1290px] mx-auto rounded-md w-full mdw-[60%] bg-black bg-opacity-10 md:-mt-[rem]">
            <p
              className="text-lg text-white"
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                letterSpacing: "0.02em",
              }}
            >
              Connect With A Professional Counselor For Free
            </p>
            <p
              className="text-lg md:text-xl mt-1 text-white"
              style={{
                fontFamily: "Poppins",
                letterSpacing: "0.02em",
              }}
            >
              Get Free, Confidential Support From Certified Counselors To
              <br className="hidden md:block" />
              Manage Stress And Anxiety.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
