import React from "react";
import authorImage from "../../Images/authorImage.jpg";

const BlogCard = ({
  image,
  title,
  author,
  date,
  shares,
  description,
  categories,
}) => {
  return (
    <div className="bg-white roundedlg shadowmd overflow-hidden">
      <img src={image} alt={title} className="w-full h-64 object-cover" />
      <div className="p-5 pl-0">
        <h3 className="font-normal leading-[40px] text-[30px]">{title}</h3>
        <div className="flex flex-row justify-between mt-3 items-center">
          <div className="roundedfull overflow-hidden">
            <img
              src={authorImage}
              alt=""
              className="w-6 h-6 rounded-full object-cover sm:w-10 sm:h-10 md:w-12 md:h-12"
            />
          </div>
          <div className="text-black text-lg font-bold lg:font-medium max-sm:font-normal max-sm:text-sm">
            By {author}
          </div>
          <div className=" text-gray-500 text-sm sm:text-base max-sm:text-xs ">
            {date}
          </div>
          <div className=" text-gray-500 max-sm:text-sm">
            <span className="flex items-center space-x-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 sm:w-6 sm:h-6 max-sm:w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                />
              </svg>
              {shares}
            </span>
          </div>
        </div>
        <p className="mt-4 text-gray-600 text-sm sm:text-base">{description}</p>
        <a
          href="#"
          className="mt-4 inline-block text-black font-bold text-lg border-b-2 border-black"
        >
          View Post
        </a>
      </div>
    </div>
  );
};

export default BlogCard;
