import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Images/asp_png.png";
import Image1 from "./Exlorer_Illustration 1.png";
import Home from "./home.png";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  });
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://50df-2405-201-3004-9249-b1de-d3b9-e0d7-c0bb.ngrok-free.app/send-email",
        formData,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Response:", response.data);
      alert("Form submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Failed to submit the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <nav className="flex justify-between items-center">
        <div className="flex items-center">
          <img src={Logo} alt="Aspirant's Ally Logo" className="w-[12rem] object-contain" />
        </div>
        <div className="flex gap-4 items-center">
          <Link to={"/"} className="text-gray-600 hover:text-gray-800">
            Home /
          </Link>
          <Link to={"/contact"} className="text-gray-600 hover:text-gray-800">
            Contact us
          </Link>
          <div className="relative top-0 right-0 w-28 h-28 bg-amber-400 rounded-bl-full flex items-center justify-center">
            <button className="text-white mr-4 ml-8" onClick={() => navigate("/")}>
              <img src={Home} alt="Home Icon" className="w-12 h-12" />
            </button>
          </div>
        </div>
      </nav>

      <div className="flex min-h-screen items-center justify-center relative overflow-hidden">
        <h1 className="absolute bottom-20 left-44 text-9xl font-semibold text-gray-200 opacity-30 pointer-events-none">
          Aspirant’s Ally
        </h1>

        <div className="flex w-full h-full relative z-10">
          <div className="flex-1 flex items-center justify-center p-8">
            <div className="max-w-md w-full mb-[12rem]">
              Your Data is Safe With Us,{" "}
              <span className="text-[#F6BD60]">Sign Up</span>
              <form className="mt-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="text-sm text-gray-600">
                      First Name
                    </label>
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="text-sm text-gray-600">
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="mobileNumber" className="text-sm text-gray-600">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    type="tel"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="email" className="text-sm text-gray-600">
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="py-3 px-8 mt-6 bg-yellow-500 hover:bg-yellow-600 text-white font-bold rounded-full"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
          <div className="flex-1 hidden lg:flex items-center justify-center">
            <img src={Image1} alt="Poster Banner" className="max-w-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
